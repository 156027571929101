import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { baseTransitionSecond, easeOut } from 'style/variables';
import { InView } from 'react-intersection-observer';

type FadeUpSectionProps = {
  moveBig?: boolean;
};

const Container = styled('div')<FadeUpSectionProps>`
  ${props =>
    props.moveBig
      ? css`
          transform: translateY(90px);
        `
      : css`
          transform: translateY(20px);
        `}
  opacity: 0;
  transition: ${baseTransitionSecond * 3}s ${easeOut};
  will-change: transform, opacity;
  &.is-shown {
    transform: translateY(0);
    opacity: 1;
  }
`;

const FadeUpSection: React.FC<FadeUpSectionProps> = props => {
  const container = React.useRef<HTMLDivElement>(null);
  const [isShown, setIsShown] = React.useState(false);
  const resetStyle = () => {
    if (container.current && !props.moveBig) {
      container.current.style.transform = 'unset';
      container.current.style.willChange = 'unset';
    }
  };
  React.useEffect(() => {
    if (container.current) {
      container.current.addEventListener('animationend', resetStyle);
    }
    return () => {
      if (container.current) {
        container.current.removeEventListener('animationend', resetStyle);
      }
    };
  }, []);
  return (
    <InView
      onChange={(inView, entry) => {
        if (inView) {
          setIsShown(true);
        }
      }}
      rootMargin={'-30%'}
    >
      <Container ref={container} className={isShown ? 'is-shown' : undefined}>
        {props.children}
      </Container>
    </InView>
  );
};

export default FadeUpSection;
