import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import Img from 'components/atoms/img';
import { TextSet } from 'components/molecules/textSet';
import React from 'react';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import FadeUpSection from 'components/organisms/fadeUpSection';

type AboutContentProps = {
  imgs: Array<ImgsObject>;
  isImgRight?: boolean;
};

export type ImgsObject = {
  path: string;
  alt: string;
};

const Container = styled.div<AboutContentProps>`
  opacity: 1;
  ${props => props.isImgRight && `flex-direction: row-reverse`};
  ${mq.onlypc} {
    ${contentWrapper}
    display: flex;
    position: relative;
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    margin-bottom: 0;
  }
`;

const ImageArea = styled.div<AboutContentProps>`
  ${mq.onlypc} {
    margin-top: ${sizes.margin[100]};
    margin-bottom: ${sizes.margin[226]};
    min-width: ${(600 / 1080) * 100}%;
    min-height: 640px;
    flex-shrink: 1;
    ${props =>
      props.isImgRight
        ? css`
            margin-left: ${sizes.margin[124]};
            margin-right: calc((1080px - 100vw) / 2);
          `
        : css`
            margin-right: ${sizes.margin[124]};
            margin-left: calc((1080px - 100vw) / 2);
          `}
  }
  ${mq.onlypcmin} {
    ${props => (props.isImgRight ? `margin-right` : `margin-left`)}: -80px;
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[64]};
    margin-left: -${sizes.margin[32]};
    margin-right: -${sizes.margin[32]};
    margin-bottom: ${sizes.margin[17]};
  }
`;

const ImageWrapper = styled.div`
  ${mq.onlypc} {
    /* 画像の高さをheightに設定 */
    height: 640px;
    top: calc(50vh - 640px / 2);
    position: sticky;
  }
  ${mq.onlysp} {
    height: 400px;
  }
`;

const CrossFade = keyframes`
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const ImageItem = styled.div`
  position: absolute;
  width: 100%;
  ${mq.onlypc} {
    &:nth-of-type(1) {
      animation: ${CrossFade} 9s infinite;
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      animation: ${CrossFade} 9s infinite;
      animation-delay: 3s;
    }
    &:nth-of-type(3) {
      animation: ${CrossFade} 9s infinite;
      animation-delay: 6s;
    }
  }
  ${mq.onlysp} {
    position: relative;
    height: 100%;
    .a-image {
      height: 100%;
    }
  }
  &.is-hidden {
    opacity: 0;
  }
  & + & {
    ${mq.onlysp} {
      visibility: hidden;
    }
  }
`;

const TextArea = styled.div`
  position: relative;
  ${mq.onlysp} {
    margin-bottom: -${sizes.margin[64]};
  }
`;

export const AboutContent: React.FC<AboutContentProps> = props => {
  return (
    <FadeUpSection>
      <Container {...props}>
        <ImageArea id={'imageArea'} {...props}>
          <ImageWrapper>
            {props.imgs?.map((item, idx) =>
              idx === 0 ? (
                <ImageItem className={'m-imageItem'} key={idx}>
                  <Img filename={item.path} alt={item.alt} />
                </ImageItem>
              ) : (
                <ImageItem className={'m-imageItem is-hidden'} key={idx}>
                  <Img filename={item.path} alt={item.alt} />
                </ImageItem>
              )
            )}
          </ImageWrapper>
        </ImageArea>
        <TextArea>
          <TextSet>{props.children}</TextSet>
        </TextArea>
      </Container>
    </FadeUpSection>
  );
};
