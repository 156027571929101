import React from 'react';
import styled from '@emotion/styled';
import sizes from 'style/sizes';
import { mq } from 'style/variables';

type TextSetProps = {
  headingSize?: 'xsmall' | 'small' | 'middle' | 'large';
  noMargin?: boolean;
};

const Container = styled.div<TextSetProps>`
  ${props => !props.noMargin && `margin-bottom: ${sizes.margin[80]};`}
  ${mq.onlysp} {
    ${props => !props.noMargin && `margin-bottom: ${sizes.margin[64]};`}
  }
  & > * {
    & + * {
      margin-top: ${props => (props.headingSize === 'xsmall' ? sizes.margin[32] : '48px')};
      ${mq.onlysp} {
        margin-top: ${props => (props.headingSize === 'xsmall' ? sizes.margin[32] : '38px')};
      }
    }
  }

  /*
  > :first-of-type {
    margin-top: 0;
  }
  > h3,
  > h2,
  > h4 {
    margin-top: 81px;
    margin-bottom: ${sizes.margin[49]};
  } */
`;

export const TextSet: React.FC<TextSetProps> = props => {
  return <Container {...props}>{props.children}</Container>;
};
