import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { AboutNav } from 'components/organisms/aboutNav';
import { AboutSection } from 'components/organisms/aboutSection';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import sizes from 'style/sizes';
import { mq, breakpoint } from 'style/variables';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { AboutContent, ImgsObject } from 'components/organisms/aboutContent';
import FadeUpItem from 'components/organisms/fadeUpItem';
import { EnHeading } from 'components/atoms/enHeading';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const MainText = styled.div`
  margin-top: ${sizes.margin[64]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
  }
  p {
    font-family: ${fonts.jaGothic};

    & + p {
      margin-top: 2em;
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;

const AboutText = styled(Text)`
  ${text(15, 2.5)};
  ${mq.onlysp} {
    ${text(15, 2.2)};
  }
  & + & {
    margin-top: 3.7em;
    ${mq.onlysp} {
      margin-top: 3em;
    }
  }
`;

const Images: ImgsObject[] = [
  {
    path: 'about/vision/img-vision01.jpg',
    alt: '',
  },
  {
    path: 'about/vision/img-vision02.jpg',
    alt: '',
  },
  {
    path: 'about/vision/img-vision03.jpg',
    alt: '',
  },
];

const Pages: React.FC<IndexPageProps> = () => {
  const [isSp, setIsSp] = useState(false);

  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
  }, []);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <>
      <SEO title={'Vision | About'} />
      <ContentWrapper>
        <AboutNav
          currentPage="Studios"
          links={[
            { text: 'Company', link: '/en/about/company' },
            { text: 'Studios', link: '/en/about/studios' },
            { text: 'Vision', link: '/en/about/vision', active: true },
            { text: 'Culture', link: '/en/about/culture' },
            { text: 'Sustainability', link: '/en/about/sustainability' },
          ]}
        />
        <AboutSection>
          <FadeUpItem delay={0.6}>
            <EnHeading>
              Making a better future <br className="only-pc" />
              through design & tech
            </EnHeading>
          </FadeUpItem>
          <FadeUpItem delay={0.8}>
            <MainText>
              <AboutText>
                Making ever-evolving digital technology a familiar part of people&apos;s daily lives and activities, and
                connecting it to more natural experiences and a richer world. Bringing value and meaning that connects
                with the future for every service and product. FOURDIGIT derives experiences as they should be,
                addressing all the processes that go into creating those experiences, to realize our vision: Improving
                the future through design &amp; tech.
              </AboutText>
            </MainText>
          </FadeUpItem>
        </AboutSection>
        <AboutContent key={``} imgs={Images} isImgRight={false}>
          <AboutSection>
            <FadeUpSection>
              <EnHeading>Design for a better future</EnHeading>
              <MainText>
                <AboutText>
                  The evolution of digital technology goes on unfolding. It will continue to improve productivity for
                  businesses, and eliminate waste from and refine people’s activities. Meanwhile, questions are being
                  asked about the value of endeavors that merely accelerate the economy. We derive experiences as they
                  should be, out of value and meaning that connects to the future, realizing them by employing our
                  strengths in design and tech.
                </AboutText>
              </MainText>
            </FadeUpSection>
          </AboutSection>
          <AboutSection>
            <FadeUpSection>
              <EnHeading>For beauty in people’s lives</EnHeading>
              <MainText>
                <AboutText>
                  FOURDIGIT is in sync with the concept of "the beauty of use," which considers not only the outward and
                  the inherent beauty of things themselves, but also their beauty when melded into our lives. That means
                  design that makes people’s lives beautiful, naturally. Although the latest technology and fashion
                  tastes change with the times, there are unchanging universal values and aesthetics that should be
                  preserved. What we get involved with, what we create, contributes to the beauty of the world. That’s
                  the sort of team we want to be.
                </AboutText>
              </MainText>
            </FadeUpSection>
          </AboutSection>
          <AboutSection>
            <FadeUpSection>
              <EnHeading>Addressing every process</EnHeading>
            </FadeUpSection>
            <FadeUpSection>
              <MainText>
                <AboutText>
                  To bring something to completion means to address all the experiences that arise out of that process.
                  Users’ expectations, trust in the brand, how people respond while using a product, the firm’s
                  operations, and the economic activity that gives rise to. We address everything from the process of
                  discovering opportunities before ideas are formed to the creation of visuals and interfaces,
                  implementation, and beyond. We address all ongoing activities, activities that continue giving rise to
                  experiences.
                </AboutText>
              </MainText>
            </FadeUpSection>
          </AboutSection>
        </AboutContent>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutVisionEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
